<template>
  <v-list three-line>
    <v-card>
      <v-list-item v-for="r in referrers" :key="r.referralUrl" @click="showReferralDetails(r)">
        <v-list-item-avatar>
          <v-icon>mdi-account-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{r.name}}</v-list-item-title>
          <v-list-item-subtitle>
            連絡先：
            {{r.telno}}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            募集者数：
            {{applied(r)}}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon>
            <v-icon :color="applied(r) > 0 ? 'info' : 'grey'">mdi-information-outline</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-card>
  </v-list>
</template>

<script>
import { getAllStaffData } from '@/helpers/firebase-functions'
import { mapState } from 'vuex'

export default {
  name: 'referrers',
  components: {
  },
  props: {
    ownerId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    referrers: []
  }),
  computed: {
    ...mapState('auth', ['uid'])
  },
  methods: {
    applied (r) {
      const { applicants } = r
      return applicants ? applicants.length : 0
    },
    showReferralDetails (r) {
      this.$router.push(`/referrer-details/${r.uid}`)
    }
  },
  async created () {
    // const ownerId = this.uid
    const ownerScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID
    const { data } = await getAllStaffData({ ownerId: this.ownerId, ownerScenarioId })
    this.referrers = data.sort((a, b) => {
      if (a.name < b.name) return -1
      else if (a.name === b.name) return 0
      else return 1
    })
  }
}
</script>
