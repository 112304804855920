<template>
  <v-card>
    <v-card-title>ホーム（ダッシュボード）</v-card-title>
    <v-expansion-panels v-model="panel">
      <!-- <v-expansion-panel>
          <v-expansion-panel-header>アンケート結果</v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <div v-if="loaded">
              採用・不採用
              <questionaire-statistics name="one" :reasons="hiredNotHired"></questionaire-statistics>採用の理由
              <questionaire-statistics name="two" :reasons="hiredReasons"></questionaire-statistics>不採用の理由
              <questionaire-statistics name="three" :reasons="notHiredReasons"></questionaire-statistics>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>-->
      <!-- <v-expansion-panel>
          <v-expansion-panel-header>スタッフの最近のコメント</v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <staff-comments :staffComments="staffComments"></staff-comments>
          </v-expansion-panel-content>
        </v-expansion-panel>-->
      <v-expansion-panel>
        <v-expansion-panel-header>紹介者リスト</v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <referrers :ownerId="ownerId"></referrers>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- <v-expansion-panel>
          <v-expansion-panel-header>スタッフ感情指数</v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <staff-sentiments :sentiments="sentiments"></staff-sentiments>
          </v-expansion-panel-content>
        </v-expansion-panel>-->
    </v-expansion-panels>
  </v-card>
</template>

<script>
import format from 'date-fns/format'
import ja from 'date-fns/locale/ja'

import { mapState, mapGetters } from 'vuex'

import { getAllSentiments, getAllReasons, getStaffComments } from '@/helpers/firebase-functions'

// import staffSentiments from '@/components/staff/staffSentiments'
// import staffComments from '@/components/staff/staffComments'
import referrers from '@/components/referral/Referrers'
// import questionaireStatistics from '@/components/questionaire/QuestionaireStatistics'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    // staffSentiments,
    // staffComments,
    referrers
    // questionaireStatistics
  },
  data: () => ({
    loaded: false,
    panel: 0,
    dialogWidth: 500,
    dialog: true,
    ownerId: '',
    ownerScenarioId: '',
    staffId: '',
    sentiments: [],
    hiredNotHired: [],
    hiredReasons: [],
    notHiredReasons: [],
    staffComments: []
  }),
  computed: {
    currentDate () {
      return format(this.now, 'yyyy/MM/dd', { locale: ja })
    },
    currentTime () {
      return format(this.now, 'HH:mm', { locale: ja })
    },
    ...mapState('auth', ['providerData', 'uid']),
    ...mapGetters('auth', ['profile']),
    telno () {
      return this.providerData[0].phoneNumber
    }
  },
  methods: {
  },
  async created () {
    this.ownerId = this.$route.params.ownerId
    this.ownerScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID

    const { allSentiments } = await getAllSentiments({
      ownerId: this.ownerId,
      ownerScenarioId: this.ownerScenarioId
    })
    this.sentiments = allSentiments

    const { data: { allReasons } } = await getAllReasons({
      ownerId: this.ownerId,
      ownerScenarioId: this.ownerScenarioId
    })
    this.hiredNotHired = allReasons.filter(r => ['hired', 'notHired'].includes(r.label))
    this.hiredReasons = allReasons.filter(r => ['clean', 'energetic', 'onTime', 'otherReasonsForHiring'].includes(r.label))
    this.notHiredReasons = allReasons.filter(r => ['notClean', 'notEnergetic', 'noShow', 'otherReasonsForNotHiring'].includes(r.label))

    const jaMap = {
      'hired': '採用',
      'notHired': '不採用',
      'clean': '清潔',
      'notClean': '清潔出ない',
      'energetic': '元気',
      'notEnergetic': '元気がない',
      'onTime': '時間厳守',
      'noShow': 'ノーショー',
      'otherReasonsForHiring': 'その他',
      'otherReasonsForNotHiring': 'その他'
    }
    this.hiredNotHired = this.hiredNotHired.map(r => {
      return { ...r, label: jaMap[r.label] }
    })
    this.hiredReasons = this.hiredReasons.map(r => {
      return { ...r, label: jaMap[r.label] }
    })
    this.notHiredReasons = this.notHiredReasons.map(r => {
      return { ...r, label: jaMap[r.label] }
    })

    const { staffComments } = await getStaffComments({
      ownerId: this.ownerId,
      ownerScenarioId: this.ownerScenarioId
    })
    this.staffComments = staffComments

    this.loaded = true
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_MAIN_DASHBOARD)
  }
}
</script>
